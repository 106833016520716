/* eslint-disable */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// Gatsby components
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Jumbotron from 'react-bootstrap/Jumbotron'
// import Image from 'react-bootstrap/Image';

// Custom components
import Hero from "../components/Hero"
// import Meta from "../components/Meta"
import Page from "../components/Layout/Page"
// import MainMenu from "../components/MainMenu"
// import PillButton from "../components/PillButton";
// import Subscribe from "../components/Subscribe";
// import Feature from '../components/Content/Feature';
// import FileFormats from "../components/Content/FileFormats";
// import Story from '../components/Content/Story';
import Products from "../components/Content/Products";

// Style & Content components
// import "../assets/scss/App.scss"
// import menuItems from "../data/menu-items.json"
// import logo from "../assets/images/logo.svg";
// import bannerImage from "../assets/images/banner@1600x700.png";


class HomePage extends Component {
    render() {
        const heading = <h1>Premium Vector Icons & Avatars</h1>;
        const subheading = <h2>Royalty-free vector icons for web and apps</h2>;
        return (
            <Page hero={<Hero heading={heading} subheading={subheading} />}>
                <Container className={'features pl-4 pr-4 mt-4 mb-4'}>
                    <Row>
                        <Col sm={12}>
                            <Products category={'illustration'}  />
                        </Col>
                    </Row>
                </Container>
            </Page>
        )
    }
}

export default HomePage
